import { addAnimation, elementPosition } from "./animation";

let scrollTarget: number | null = null;

function performScroll() {
  if (scrollTarget !== null) {
    const distance = scrollTarget - window.scrollY;
    let scrollAmount = distance * 0.2;
    if (scrollAmount < 1 && scrollAmount > 0) {
      scrollAmount = 1;
    }
    if (scrollAmount > -1 && scrollAmount < 0) {
      scrollAmount = -1;
    }
    if (distance >= 1 || distance <= -1) {
      window.scrollTo(0, window.scrollY + scrollAmount);
    } else {
      window.scrollTo(0, scrollTarget);
      scrollTarget = null;
    }
  }
}

export function startScrollTo() {
  addAnimation(performScroll);
}

export function scrollToElement(elem: HTMLElement | null) {
  if (elem) {
    scrollTarget = elementPosition(elem)[1];
  }
}
