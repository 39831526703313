type AnimationHandler = () => void;
type ReadyHandler = () => void;

const animationFrames: AnimationHandler[] = [];
const readyHandlers: ReadyHandler[] = [];

interface Options {
  resize?: (width: number, height: number) => void;
  scroll?: (offset: number, width: number, height: number) => void;
}

function animationLoop() {
  animationFrames.forEach((fn) => fn());
  requestAnimationFrame(animationLoop);
}

function handleState() {
  if (["interactive", "complete"].indexOf(document.readyState) > -1) {
    while (readyHandlers.length > 0) {
      readyHandlers.shift()();
    }
  }
}

export function ready(handler: ReadyHandler) {
  readyHandlers.push(handler);
  handleState();
}

export function startAnimation() {
  document.onreadystatechange = handleState;
  animationLoop();
}

export function elementPosition(elem: HTMLElement): ScrollPosition {
  let top = 0,
    left = 0;
  if (elem.offsetParent) {
    do {
      elem = elem.offsetParent as HTMLElement;
      top += elem.offsetTop;
      left += elem.offsetLeft;
    } while (elem.offsetParent);
  }
  return [left, top];
}

export function addAnimation(handler: AnimationHandler) {
  animationFrames.push(handler);
  handler();
}

export function windowAnimation(options: Options = {}) {
  let scrollOffset: number = null;
  let wWidth: number = null,
    wHeight: number = null;

  const update = function () {
    // Resize
    if (wWidth != window.innerWidth || wHeight != window.innerHeight) {
      wWidth = window.innerWidth;
      wHeight = window.innerHeight;
      scrollOffset = null;
      if (options.resize !== undefined) {
        options.resize(wWidth, wHeight);
      }
    }

    // Scroll
    if (scrollOffset != window.scrollY) {
      scrollOffset = window.scrollY;
      if (options.scroll !== undefined) {
        options.scroll(scrollOffset, wWidth, wHeight);
      }
    }
  };

  addAnimation(update);
}
