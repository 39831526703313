import { ready, windowAnimation } from "../frontend/animation";

ready(function () {
  const header = document.getElementsByTagName("header")[0];

  windowAnimation({
    scroll: function (offset) {
      if (offset > 150) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    }
  });
});
