import { elementPosition, ready, windowAnimation } from "../frontend/animation";

ready(function () {
  const hidden = [];

  function hideElements(className: string) {
    const nodelist = document.getElementsByClassName(className);
    [...nodelist].forEach(function (elem) {
      elem.classList.add("hidden");
      hidden.push(elem);
    });
  }

  function showElem(elem: HTMLElement) {
    hidden.splice(hidden.indexOf(elem), 1);
    setTimeout(function () {
      elem.classList.remove("hidden");
    }, 10);
  }

  hideElements("background");
  hideElements("team-image");

  windowAnimation({
    scroll: function (offset: number, _width: number, height: number) {
      const trigger = offset + height * 0.75;

      hidden
        .filter(function (elem: HTMLElement) {
          return elementPosition(elem)[1] <= trigger;
        })
        .forEach((elem: HTMLElement) => showElem(elem));
    }
  });
});
