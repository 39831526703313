import { ready } from "../frontend/animation";
import { scrollToElement } from "../frontend/scrollTo";

ready(function () {
  const links = document.getElementsByClassName("nav-item");
  [...links].forEach(function (link: HTMLLinkElement) {
    link.addEventListener(
      "click",
      function (evt: Event) {
        evt.preventDefault();
        const anchor = link.href.match(/#(.*)/)[1];
        scrollToElement(document.getElementsByName(anchor)[0]);
      },
      false
    );
  });
});
