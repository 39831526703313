// Rails UJS
import Rails from "@rails/ujs";
Rails.start();

// Animation
import { startAnimation } from "./frontend/animation";
import { startScrollTo } from "./frontend/scrollTo";
startAnimation();
startScrollTo();

import "./features/hero";
import "./features/images";
import "./features/nav";
